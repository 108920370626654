@font-face {
	font-family: 'extrabold';
	src: url('fonts/opensans_extrabold.ttf') format("truetype"); }
$extrabold: 'extrabold';

@font-face {
	font-family: 'bold';
	src: url('fonts/opensans_bold.ttf') format("truetype"); }
$bold: 'bold';

@font-face {
	font-family: 'regular';
	src: url('fonts/opensans_regular.ttf') format("truetype"); }
$regular: 'regular';

@font-face {
	font-family: 'light';
	src: url('fonts/opensans_light.ttf') format("truetype"); }
$light: 'light';




$gold: #a9935b;
$golddark: #856e34;
$orange: #ffdaa6;
$lightGray: #f4f1f1;
$blue: #e8a150;
$error: #bd395e;


a {
  text-decoration: none; }

a, input, button, textarea, select {
  outline: none;
  transition: all .2s; }

h1, h2 {
  font-family: $extrabold; }

h3,h4,h5,h6,strong {
  font-family: $bold; }

small {
  font-family: $light; }

body {
  background-color: $lightGray;
  font-family: $regular; }

.btnGold {
  background-color: $gold;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 12px 20px;
  width: 100%;
  font-family: $bold;
  &:hover {
    background-color: $golddark; } }

.btnWhite {
  width: 100%;
  background-color: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 12px 20px;
  border: 2px solid white;
  font-family: $bold;
  &:hover {
    border: 2px solid black; } }

.bg-gold {
  background-color: $gold; }

.icon-md {
  width: 60px; }




.headerLog {
  position: sticky;
  top: 0;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(black,.1);
  z-index: 1000;
  .logo {
    width: 110px; }
  nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btnGold {
      margin-left: 15px;
      width: fit-content; }
    .link {
      color: black;
      display: inline-flex;
      align-items: center;
      height: 50px;
      padding: 0 25px;
      border-radius: 8px;
      font-family: $bold;
      &:hover {
        background-color: rgba($orange,.3); } }
    .home {
      display: block;
      width: 50px;
      height: 50px;
      margin-right: 20px;
      background: url('../img/vector/icon_home.svg') center / 30px no-repeat; } } }




.footerLog {
  .logo {
    width: 120px; }
  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    a,button {
      color: black;
      display: inline-flex;
      padding: 0 15px;
      font-family: $bold;
      font-size: 14px;
      background-color: transparent;
      border: none;
      &:hover {
        color: $golddark; } } } }


.floatNav {
  position: fixed;
  width: 60px;
  height: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(black,.1);
  border-radius: 15px;
  padding: 10px;
  top: calc( 50vh - (120px / 2) );
  right: 10px;
  z-index: 5;
  a,button {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 5px;

    &.instagram {
      background: url('../img/vector/instagram.svg') center / 33px no-repeat; }
    &.mail {
      background: url('../img/vector/mail.svg') center / 30px no-repeat;
      margin: 10px 0; }
    &.wsp {
      background: url('../img/vector/whatsapp.svg') center / 35px no-repeat; }
    &:hover {
      background-color: $lightGray !important; } }
  @media screen and ( max-width: 768px ) {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    z-index: 1;
    top: 0;
    right: auto;
    margin-bottom: 20px;
    a, button {
      height: 30px;
      &.mail {
        margin: 0 30px; } } } }


.socialmedia {
  display: none !important;
  @media screen and ( max-width: 768px ) {
    display: flex !important; } }


.cozmo {
  font-size: 14px;
  padding: 10px 0;
  a {
    display: inline-flex;
    padding: 0px 10px;
    border-radius: 5px;
    background-color: $lightGray;
    color: black;
    &:hover {
      background-color: $blue;
      color: white; } } }


.footerMobile {
  display: none;
  position: sticky;
  bottom: 0;
  z-index: 100;
  padding: 10px 20px;
  justify-content: center;
  div {
    width: 100%;
    background-color: white;
    border-radius: 24px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(black,.1);
    display: flex;
    justify-content: center;
    align-items: center;
    button, a {
      display: block;
      border-radius: 14px;
      width: calc( 100% / 4 );
      height: 70px;
      background-color: transparent;
      border: none;
      &.home {
        background: url('../img/vector/home_footer.svg') center / 84px no-repeat; }
      &.trip {
        background: url('../img/vector/places_footer.svg') center / 90px no-repeat; }
      &.about {
        background: url('../img/vector/about_footer.svg') center / 84px no-repeat; }
      &.wsp {
        background: $golddark url('../img/vector/wsp_white.svg') center / 50px no-repeat; } } }
  @media screen and ( max-width: 768px ) {
    display: flex; } }


.languages {
  background-color: $lightGray;
  padding: 5px;
  border-radius: 40px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 70px;
    height: 34px;
    border-radius: 40px;
    border: none;
    &.es {
      background: url('../img/vector/es.svg') center / contain no-repeat; }
    &.en {
      background: url('../img/vector/en.svg') center / contain no-repeat; }
    &.active {
      background-color: white;
      box-shadow: 0 0 10px rgba($gold,.3); } } }


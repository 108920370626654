.formSection {
  background: $blue;
  padding-bottom: 40px; }

.cardForm {
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  transform: translateY(-40px);
  .content {
    .item {
      label {
        font-size: 14px;
        margin-bottom: 10px; }
      input {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: none;
        padding: 0 10px;
        color: black;
        background-color: $lightGray;
        &.error {
          color: $error;
          border: 1px solid $error; } } }
    button {
      width: 100%;
      height: 50px;
      background-color: black;
      border: 2px solid black;
      color: white;
      font-family: $bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      &:hover {
        background-color: $gold;
        border: 2px solid $gold; } } }
  @media screen and ( max-width: 768px ) {
    padding: 30px; } }




.inputSelect {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: none;
  background-color: $lightGray;
  position: relative;
  select {
    background-color: transparent;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 15px;
    -webkit-appearance: none;
    text-align: center;
    &.error {
        border: 1px solid $error;
        border-radius: 8px; } } }

#home {
  background: url('../img/brand/bg.jpg');
  background-size: contain;
  background-attachment: fixed;
  background-repeat: no-repeat;
  @media screen and ( max-width: 768px ) {
    background: linear-gradient(#baddf2, transparent); } }


.labelWhyUs {
  background-color: $orange;
  padding: 10px;
  border-radius: 8px; }

.btnWhatsApp {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 15px 30px;
  border: 2px solid black;
  background-color: white;
  color: black;
  border-radius: 40px;
  &::before {
    content: '';
    width: 34px;
    height: 34px;
    margin-right: 10px;
    background: url('../img/vector/icon_wsp.svg') center / contain no-repeat; }
  &:hover {
    background-color: black;
    color: white;
    &::before {
      background: url('../img/vector/icon_wsp_white.svg') center / contain no-repeat; } } }



.cardTrip {
  display: block;
  width: 100%;
  background-color: white;
  border-radius: 14px;
  box-shadow: 0 0 10px rgba(black,.05);
  padding: 15px;
  border: none;
  transition: all .3s;
  img {
    width: 100%;
    height: auto;
    aspect-ratio: 2/1.1;
    margin-bottom: 20px;
    border-radius: 8px 8px 50px 8px; }

  .row {
    .col-6 {
      &:nth-child(1) {
        text-align: start; }
      &:nth-child(2) {
        text-align: end; }
      h5 {
        font-size: 20px; }
      .schedule {
        background-color: $lightGray;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        font-family: $light;
        padding: 5px;
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          margin-right: 5px;
          background: url('../img/vector/clock.svg') center / 17px no-repeat; } }
      p {
        text-align: center;
        padding-top: 5px;
        span {
          font-size: 14px;
          font-family: $light;
          display: inline-flex;
          margin-right: 6px; }
        strong {
          letter-spacing: .5px; } } } }
  &:hover {
    box-shadow: 0 0 20px rgba($blue,.3);
    transform: scale(1.01); } }


.nav-tabs {
  background-color: white;
  border-radius: 14px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  li {
    background-color: transparent;
    border: none;
    .nav-link {
      white-space: nowrap;
      padding: 10px 20px;
      border-radius: 10px;
      color: black;
      border: none;
      &:hover {
        background-color: $light !important; }
      &.active {
        background-color: $gold;
        color: white;
        font-family: $bold; } } } }

.itemList {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-family: $light;
  font-size: 20px;
  &::before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url('../img/vector/checked.svg') center / contain no-repeat; } }


.cardComments {
  background-color: white;
  border-radius: 14px;
  padding: 24px;
  margin-top: 10px;
  h5 {}
  p {} }

.swiper-pagination {
  margin-top: 20px;
  position: relative !important;
  span {
    width: 14px;
    height: 5px;
    border-radius: 5px;
    transition: all .3s;
    background-color: rgba(black,.2);
    opacity: 1; }
  .swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
      width: 25px;
      background-color: $gold; } } }

